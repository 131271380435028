import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ContentBitType} from '../../enums/content-bit-type.enum';
import {ContentBitModel} from '../../models/content-bit.model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BitTypeConfig} from '../../config/bit-type.config';
import {
  bubblePopAnswersConfig, calculatorAnswersConfig, clickToPopAnswersConfig,
  ctInformationalAnswersConfig,
  fillInBlanksAnswersConfig,
  openAnswersConfig,
  pairMatchingMediaAnswersConfig,
  pairMatchingMediaFlipAnswersConfig,
  pairMatchingTextAnswersConfig,
  selectMediaAnswersConfig,
  selectTextAnswersConfig,
  sentenceBuildAnswersConfig, slingshotAnswersConfig, speakOutLoudAnswersConfig, speakWithOverlayAnswersConfig,
  spriteDragAndDropAnswersConfig,
  switichingAnswersConfig,
  tracingShapeAnswersConfig,
  wordBuildAnswersConfig,
  wordTranslationAnswersConfig
} from '../../utils/answers-configs';
import {
  clickToPopMainTextConfig, ctInformationalMainTextConfig,
  defaultMainTextConfig,
  fragmentMainTextConfig,
  lipSyncMainTextConfig,
  spriteDragAndDropMainTextConfig,
  titleMainTextConfig,
  VideoSimpleMainTextConfig
} from '../../utils/main-text-configs';
import {defaultPreTextConfig} from '../../utils/pre-text-configs';
import {defaultDidYouKnowConfig} from '../../utils/did-you-know-configs';
import {defaultTipTextConfig} from '../../utils/tip-text-configs';
import {defaultSelectedStatus} from '../../../../shared/components/status-select/status-select.component';
import {calculatorConfig, puzzleConfig, speakOutLoudConfig, switchingPropertiesConfig, tracingShapePropertiesConfig, videoSimplePropertiesConfig} from '../../utils/content-bits-configs';
import {ContentByteModel} from '../../../content-bytes/models/content-byte.model';
import {ContentByteType} from '../../../content-bytes/models/content-byte-type.enum';

@Component({
  selector: 'app-content-bit-form',
  templateUrl: './content-bit-form.component.html',
  styleUrls: ['./content-bit-form.component.scss'],
})
export class ContentBitFormComponent implements OnInit, OnChanges {

  @Input()
  contentBit: ContentBitModel;

  @Input()
  contentByte: ContentByteModel;

  @Input()
  isNewContentByte = false;

  @Output()
  public save = new EventEmitter<ContentBitModel>();

  selectedTypeConfig: BitTypeConfig = null;

  types: BitTypeConfig[] = [
    {
      title: 'Title',
      name: ContentBitType.TITLE,
      has_answers: false,
      answers_config: null,
      pre_text: false,
      main_text: true,
      main_text_config: titleMainTextConfig,
      tip_text: false,
      did_you_know: false,
      properties_config: null,
    },
    {
      title: 'Tracing Shape',
      name: ContentBitType.TRACING_SHAPE,
      has_answers: true,
      answers_config: tracingShapeAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: tracingShapePropertiesConfig,
    },
    {
      title: 'Fragment',
      name: ContentBitType.FRAGMENT,
      has_answers: false,
      answers_config: null,
      pre_text: true,
      main_text: true,
      main_text_config: fragmentMainTextConfig,
      tip_text: false,
      did_you_know: false,
      properties_config: null,
    },
    {
      title: 'Lip Sync',
      name: ContentBitType.LIP_SYNC,
      has_answers: false,
      answers_config: null,
      pre_text: true,
      main_text: true,
      main_text_config: lipSyncMainTextConfig,
      tip_text: false,
      did_you_know: false,
      properties_config: null,
    },
    {
      title: 'Video Simple',
      name: ContentBitType.VIDEO_SIMPLE,
      has_answers: false,
      answers_config: null,
      pre_text: true,
      main_text: true,
      main_text_config: VideoSimpleMainTextConfig,
      tip_text: true,
      did_you_know: true,
      properties_config: videoSimplePropertiesConfig,
    },
    {
      title: 'Select Text',
      name: ContentBitType.SELECT_TEXT,
      answers_config: selectTextAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Select Media',
      name: ContentBitType.SELECT_MEDIA,
      answers_config: selectMediaAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Pair Matching Text',
      name: ContentBitType.PAIR_MATCHING_TEXT,
      answers_config: pairMatchingTextAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Pair Matching Media',
      name: ContentBitType.PAIR_MATCHING_MEDIA,
      answers_config: pairMatchingMediaAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Pair Matching Media Flip',
      name: ContentBitType.PAIR_MATCHING_MEDIA_FLIP,
      answers_config: pairMatchingMediaFlipAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Sentence Building',
      name: ContentBitType.SENTENCE_BUILDING,
      answers_config: sentenceBuildAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Open',
      name: ContentBitType.OPEN,
      answers_config: openAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Fill In Blanks',
      name: ContentBitType.FILL_IN_BLANKS,
      answers_config: fillInBlanksAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Bubble pop',
      name: ContentBitType.BUBBLE_POP,
      answers_config: bubblePopAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Bubble pop fish',
      name: ContentBitType.BUBBLE_POP_FISH,
      answers_config: bubblePopAnswersConfig,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      tip_text_config: defaultTipTextConfig,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Coloring Freeform',
      name: ContentBitType.COLORING_FREEFORM,
      has_answers: false,
      answers_config: null,
      pre_text: true,
      main_text: true,
      main_text_config: fragmentMainTextConfig,
      tip_text: false,
      did_you_know: false,
      properties_config: null,
    },
    {
      title: 'CT Informational',
      name: ContentBitType.CT_INFORMATIONAL,
      has_answers: true,
      answers_config: ctInformationalAnswersConfig,
      pre_text: false,
      main_text: false,
      main_text_config: ctInformationalMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Puzzle',
      name: ContentBitType.PUZZLE,
      has_answers: false,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: puzzleConfig,
    },
    {
      title: 'Calculator',
      name: ContentBitType.CALCULATOR,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: calculatorConfig,
      answers_config: calculatorAnswersConfig,
    },
    {
      title: 'Word Translation',
      name: ContentBitType.WORD_TRANSLATION,
      has_answers: true,
      answers_config: wordTranslationAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Word Build',
      name: ContentBitType.WORD_BUILD,
      has_answers: true,
      answers_config: wordBuildAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Word Build Child Name',
      name: ContentBitType.WORD_BUILD_CHILD_NAME,
      has_answers: false,
      answers_config: null,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Sprite Drag And Drop',
      name: ContentBitType.SPRITE_DRAG_AND_DROP,
      has_answers: true,
      answers_config: spriteDragAndDropAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: spriteDragAndDropMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Click to pop',
      name: ContentBitType.CLICK_TO_POP,
      has_answers: true,
      answers_config: clickToPopAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: clickToPopMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
    },
    {
      title: 'Speak Out Loud',
      name: ContentBitType.SPEAK_OUT_LOUD,
      has_answers: true,
      answers_config: speakOutLoudAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: speakOutLoudConfig,
    },
    {
      title: 'Speak Out Loud Video',
      name: ContentBitType.SPEAK_OUT_LOUD_VIDEO,
      has_answers: false,
      answers_config: speakOutLoudAnswersConfig,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: speakOutLoudConfig,
    },
    {
      title: 'Speak With Overlay',
      name: ContentBitType.SPEAK_WITH_OVERLAY,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
      answers_config: speakWithOverlayAnswersConfig,
    },
    {
      title: 'Switching',
      name: ContentBitType.SWITCHING,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: switchingPropertiesConfig,
      answers_config: switichingAnswersConfig,
    },
    {
      title: 'Slingshot',
      name: ContentBitType.SLINGSHOT,
      has_answers: true,
      pre_text: true,
      pre_text_config: defaultPreTextConfig,
      main_text: true,
      main_text_config: defaultMainTextConfig,
      tip_text: false,
      did_you_know: true,
      did_you_know_config: defaultDidYouKnowConfig,
      properties_config: null,
      answers_config: slingshotAnswersConfig,
    },
  ];

  fragmentType: string = ContentBitType.FRAGMENT;
  selectTextType: string = ContentBitType.SELECT_TEXT;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      properties: [],
      status: [this.defaultStatus],
      type: [],
      pre_text: [],
      main_text: [],
      tip_text: [],
      did_you_know: [],
      answers: [],
    });
  }

  get defaultStatus(): string {
    return defaultSelectedStatus;
  }

  get typeControl(): FormControl {
    return this.form.controls.type as FormControl;
  }

  get type(): any {
    return this.typeControl.value;
  }

  isNewStoryContentByte(): boolean {
    return this.isNewContentByte && this.contentByte?.type === ContentByteType.STORY;
  }

  isNewSkillContentByte(): boolean {
    return this.isNewContentByte && this.contentByte?.type === ContentByteType.SKILL;
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const {value} = this.form;

    this.save.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.reset({status: this.defaultStatus});

    if (changes.contentBit && this.contentBit) {
      this.form.patchValue({
        ...this.contentBit,
      });
    }

    if (this.isNewStoryContentByte()) {
      this.form.patchValue({
        type: this.fragmentType
      });
    } else if (this.isNewSkillContentByte()) {
      this.form.patchValue({
        type: this.selectTextType
      });
    }

    this.form.markAsPristine();
  }

  typeChanged($event: any): void {
    this.selectedTypeConfig = this.types.filter((item) => item.name === $event)[0];
  }
}
