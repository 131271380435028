import {MediaConfig} from '../config/media.config';
import {spriteDragAndDropPropertiesConfig} from '../config/media-properties.config';

export const defaultMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};

export const titleMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: false,
  widget_type: false,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};


export const fragmentMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};

export const lipSyncMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};

export const VideoSimpleMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  video_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};


export const spriteDragAndDropMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  properties: null,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};


export const clickToPopMainTextConfig: MediaConfig = {
  text: true,
  image_url: true,
  audio_url: true,
  avatar_image_url: true,
  widget_type: true,
  with_tts: true,
  properties: null,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: true,
  image_color: true,
};



export const ctInformationalMainTextConfig: MediaConfig = {
  text: false,
  image_url: false,
  audio_url: false,
  avatar_image_url: false,
  widget_type: false,
  with_tts: false,
  properties: null,
  language: true,
  text_direction: true,
  layout_alignment: true,
  has_speech_marks: false,
  image_color: true,
};

