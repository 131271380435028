import {ContentBitPropertiesConfig} from '../config/content-bit-properties.config';

export const puzzleConfig: ContentBitPropertiesConfig = {
  columns_count: true,
  rows_count: true,
  hide_original_image: true,
  error_margin: true,
  language: false,
  thousand: null,
  decimal: null,
  switch_type:false,
  switch_slot:false,
  word_similarity_threshold:false,
  sentence_similarity_threshold: false,
  video_container: false,
  show_controls: false,
};

export const speakOutLoudConfig: ContentBitPropertiesConfig = {
  columns_count: false,
  rows_count: false,
  hide_original_image: false,
  error_margin: false,
  language: false,
  thousand: null,
  decimal: null,
  switch_type:false,
  switch_slot:false,
  word_similarity_threshold:true,
  sentence_similarity_threshold: true,
  video_container: false,
  show_controls: false,
};

export const calculatorConfig: ContentBitPropertiesConfig = {
  columns_count: false,
  rows_count: false,
  hide_original_image: false,
  error_margin: false,
  language: true,
  switch_type:false,
  switch_slot:false,
  thousand: {
    check_for_this: true,
    visible: true,
    modifier: true,
  },
  decimal: {
    check_for_this: true,
    visible: true,
    modifier: true,
  },
  word_similarity_threshold:false,
  sentence_similarity_threshold: false,
  video_container: false,
  show_controls: false,
};


export const tracingShapePropertiesConfig: ContentBitPropertiesConfig = {
  columns_count: false,
  rows_count: false,
  hide_original_image: false,
  error_margin: true,
  language: false,
  thousand: null,
  decimal: null,
  switch_type:false,
  switch_slot:false,
  word_similarity_threshold:false,
  sentence_similarity_threshold: false,
  video_container: false,
  show_controls: false,
};

export const switchingPropertiesConfig: ContentBitPropertiesConfig = {
  columns_count: false,
  rows_count: false,
  hide_original_image: false,
  error_margin: false,
  language: false,
  thousand: null,
  decimal: null,
  switch_type:true,
  switch_slot:true,
  word_similarity_threshold:false,
  sentence_similarity_threshold: false,
  video_container: false,
  show_controls: false,

};

export const videoSimplePropertiesConfig: ContentBitPropertiesConfig = {
  columns_count: false,
  rows_count: false,
  hide_original_image: false,
  error_margin: false,
  language: false,
  thousand: null,
  decimal: null,
  switch_type:false,
  switch_slot:false,
  word_similarity_threshold:false,
  sentence_similarity_threshold: false,
  video_container: true,
  show_controls: true,
};

